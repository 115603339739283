<template>
    <Header :isShow="isShow" />
    <div class="wrpaer" >
        <!-- 简介 -->
        <div class="introduction_box" ref="header">
            <div class="introduction max_small_box clearfix" v-if="detail">
                <div class="img" data-aos="fade-right">
                    <img :src="imgUrl + detail.image" alt="">
                </div>
                <div class="content" data-aos="fade-left">
                    <div class="text">{{detail.desc}}</div>
                </div>
            </div>
        </div>
        <!-- 文化中心 -->
        <div class="culture_box" v-if="detail">
            <div class="culture max_small_box">
                <div class="top">
                    <p class="title">中心文化</p>
                    <div class="line"></div>
                </div>
                <div class="under">
                    <div class="left" data-aos="fade-right">
                        <p class="title">我们的价值观、愿景和使命</p>
                        <div class="desc">
                            <p><span>质量方针：</span>科学、公正、准确、满意</p>
                            <p><span>价值观：</span>敏捷、专业、成就客户</p>
                            <p><span>使命：</span>服务创新、传递信任，守望美好生活。</p>
                            <p><span>愿景：</span>致力于成为客户信赖的质量需求服务提供方！</p>
                        </div>
                    </div>
                    <div class="right" data-aos="fade-left">
                        <img src="@/assets/images/about1.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 发展历程 -->
        <div class="development_box">
            <div class="development max_small_box">
                <div class="top">
                    <p class="title">发展历程</p>
                    <div class="line"></div>
                </div>
                <div class="under" v-if="detail">
                    <div class="list" v-for="(item, index) in detail.content" :key="index">
                        <div class="round_big">
                            <div class="round_small"></div>
                        </div>
                        <div class="line"></div>
                        <div class="content" data-aos="zoom-in-up">
                            <p class="time">{{item.title}}</p>
                            <div class="desc" v-html="item.desc"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理函数
const detailEffect = () => {
    //详情
    const detail = ref()

    //获取详情
    const getDetail = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id
        }
        await post('/about/get_about', postData).then((res) => {
            // console.log('中心简介', res)
            if(res.code == 1) {
                res.data.data[0].content = res.data.data[0].content.reverse()
                detail.value = res.data.data[0]
            }
        })
    }

    return { detail, getDetail }
}

export default {
    name: 'AboutCenter',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理函数
        const { detail, getDetail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            const  route = useRoute()
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
               if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
            getDetail(route.params.id, route.params.page_id)
        });

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail }
    }
}
</script>

<style lang="scss" scoped>

/* 简介 */
.introduction{
    position: relative;
    margin-top: 40px;
    @media screen and (max-width: 1200px){
        margin-top: 0px;
    }
    .img{
        width: 30%;
        height: 200px;
        position: absolute;
        top: 56px;
        left: 80px;
        @media screen and (max-width: 1200px){
            width: 100%;
            height: auto;
            position: static;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }
    .content{
        width: 63.3%;
        min-height: 300px;
        color: #fff;
        float: right;
        background: #305AA8;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1200px){
            width: auto;
            height: auto;
            float: none;
            padding: 40px 30px;
        }
        .text{
            margin-bottom: 40px;
            line-height: 30px;
            font-size: 16px;
            @media screen and (max-width: 900px){
                font-size: .16rem;
            }
            p{
                color: rgba(255, 255, 255, 100) !important;
            }
        }
    }
}
/* 文化中心 */
.culture_box{
    margin-top: 80px;
}
.culture{
    .top{
        .title{
            color: rgba(48, 90, 168, 100);
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            @media screen and (max-width: 900px){
                font-size: .2rem;
            }
        }
        .line{
            width: 60%;
            height: 2px;
            margin: 0 auto;
            margin-top: 20px;
            background: #BBBBBB;
        }
    }
    .under{
        margin-top: 43px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
            display: block;
        }
        .left{
            width: 33%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            .title{
                color: rgba(48, 90, 168, 100);
                font-size: 24px;
                font-weight: 600;
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
            .desc{
                margin-top: 24px;
                p{
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    margin-bottom: 15px;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                    span{
                        font-weight: 600;
                        font-size: 16px;
                        @media screen and (max-width: 900px){
                            font-size: .14rem;
                        }
                    }
                }
            }
        }
        .right{
            width: 58%;
            @media screen and (max-width: 1200px){
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
    }
}
/* 发展历程 */
.development_box{
    margin-top: 120px;
}
.development{
    .top{
        .title{
            color: rgba(48, 90, 168, 100);
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            @media screen and (max-width: 900px){
                font-size: .2rem;
            }
        }
        .line{
            width: 60%;
            height: 2px;
            margin: 0 auto;
            margin-top: 20px;
            background: #BBBBBB;
        }
    }
    .under{
        margin-top: 43px;
        .list{
            width: 50%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            &:nth-child(odd){
                .content{
                    left: 0;
                    right: auto;
                    text-align: right;
                }
            }
            @media screen and (max-width: 1200px){
                width: 80%;
            }
            .round_big{
                width: 23px;
                height: 23px;
                border: 1px solid #bbb;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .round_small{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #305AA8;
                }
            }
            .line{
                border: 1px dashed #bbb;
                height: 80px;
            }
            .content{
                position: absolute;
                right: 0;
                left: auto;
                top: 0px;
                width: 40%;
                text-align: left;
                .time{
                    color: rgba(16, 16, 16, 100);
                    font-size: 16px;
                    font-weight: 600;
                    @media screen and (max-width: 900px){
                        font-size: .16rem;
                    }
                }
                .desc{
                    color: rgba(16, 16, 16, 100);
                    font-size: 14px;
                    margin-top: 12px;
                    line-height: 18px;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                }
                .img{
                    width: 100%;
                    height: 92px;
                    margin-top: 12px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>